import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'

Vue.config.productionTip = false

// const mobile_plugin = {
//   install(Vue) {
//     Vue.mixin({
//       data() {
//         return {
//           is_mobile: false
//         }
//       },
//       mounted() {
//         this.is_mobile = window.innerWidth <= 800
//         window.onresize = () => {
//           this.is_mobile = window.innerWidth <= 800
//         }
//       }
//     })
//   }
// }
//
// Vue.use(mobile_plugin)

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
