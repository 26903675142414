import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locale', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function get_locale() {
  let lang = 'ee'

  if(window.location.hostname.endsWith('.ee'))
    lang = 'ee'
  else if(window.location.hostname.endsWith('.lv'))
    lang = 'lv'
  else if(window.location.hostname.endsWith('.lt'))
    lang = 'lt'

  let url_lang = getParameterByName('lang')
  if(url_lang)
    lang = url_lang

  return lang
}


export default new VueI18n({
  locale: get_locale(),
  messages: loadLocaleMessages()
})
